import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Divider, Typography, TextField } from '@mui/material';
import { CheckmarkSquare24Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { DisposalRequestResponseStatus } from '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import { Button } from '../button/Button.js';
import { DetailsRow } from '../DetailsRow.js';
import '../../config.js';
import '../../auth/config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../themes/light.theme.js';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../constants/messages.js';
import { requestApi } from '../../services/requestApi.js';
import '../../services/userApi.js';
import '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import '../item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../store/thunks/downloadFileThunk.js';
import { DisposalRequestResponseStatusChip } from '../chip/DisposalRequestResponseStatusChip.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { UserChip } from '../chip/UserChip.js';
import { CustomActionModal } from './CustomActionModal.js';

const DisposalRequestApprovalDialog = ({ title = 'Approve request', request, open, user, onClose, onAction, }) => {
    useTheme();
    const [comment, setComment] = useState('');
    return (jsxs(CustomActionModal, { title: title, onClose: onClose, open: open, icon: jsx(CheckmarkSquare24Regular, {}), actionButton: jsx(Button, { variant: 'contained', onClick: () => {
                onAction(comment);
                requestApi.util.invalidateTags(['DisposalRequest']);
            }, children: "Approve" }), children: [jsx(DetailsRow, { label: 'Request Name', data: request?.Name, size: 'small' }), jsx(DetailsRow, { label: 'Disposal Action', data: request?.DisposalAction, size: 'small' }), jsx(DetailsRow, { label: 'Response', data: jsx(DisposalRequestResponseStatusChip, { status: DisposalRequestResponseStatus.Approved }), size: 'small', alignData: true }), jsx(DetailsRow, { label: 'Response By', data: jsx(UserChip, { user: user }), size: 'small', alignData: true }), jsx(Divider, { style: { margin: '2rem 0 2rem 0' } }), jsx(Typography, { variant: 'body1', children: "Comment" }), jsx(TextField, { sx: {
                    marginTop: '0.5rem',
                }, fullWidth: true, maxRows: 5, minRows: 5, multiline: true, onChange: (event) => setComment(event.target.value), value: comment })] }));
};

export { DisposalRequestApprovalDialog, DisposalRequestApprovalDialog as default };
